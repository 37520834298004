
<template>

  <div class="tag-suggestion">
    <div class="label-div">
      <label :class="`text-sm p-1 ${focused ? 'text-primary' : ''}`">{{this.label}}</label>
    </div>
    <vue-tags-input
    id="idtags"
    v-model="tag"
    :tags="tags"
    :autocomplete-items="suggestedTags"
    :placeholder="placeholderText"
    :separators="[';', ' ', '\n', ',']"
    @tags-changed="tagsChanged"
    :add-only-from-autocomplete="true"
    :class="`overflow-visible w-full zfix ${focused ? 'ti-input-border-primary' : ''}`"
    @focus="focused = true"
    @blur="focused = false"
    >
      <template v-slot:tag-center="slot">
        <div>{{slot.tag.text}}</div>
      </template>
    </vue-tags-input>
  </div>
  
</template>

<script>
import VueTagsInput from '@johmun/vue-tags-input'
import VxInputGroup from './vx-input-group/VxInputGroup.vue'
import SuggestionService from '@/services/api/SuggestionService'

export default{
  props: {
    placeholderText: {
      type: String,
      default: '...'
    },
    suggestionMethod: {
      type: String,
      default: 'fetchSuggestion'
    },
    service: {
      type: Object,
      default: null
    },
    modelToTagMapping: {
      type: Function,
      default: null
    },
    label: {
      type: String,
      default: 'Tag'
    },
    tags: {
      type: Array,
      default: () => []
    },
    model: {
      type: String,
      default: null
    },
    column: {
      type: String,
      default: null
    },
    max: {
      type: Number,
      default: 5
    },
    with: {
      type: Array,
      default: () => []
    }
  },

  model: {
    prop: 'tags',
    event: 'input'
  },

  components: {
    VueTagsInput, VxInputGroup
  },

  watch: {
    tag(value) {
      this.suggest(value)
    },
    selectedQuestions() {
      this.tagsChanged()
    }
  },

  data () {
    return {
      tag: '',
      suggestedTags: [],
      timeout: null,
      focused: false
    }
  },

  mounted() {
    if (this.service === null) {
      this.service = SuggestionService.buildOnModel(
        this.model,
        this.column,
        this.max,
        this.with,
        this.$vs
      )
    }
  },

  computed: {
    formatedTags() {
      return this.tags.map((tag) => ({...tag, classes: 'bg-primary'}))
    }
  },

  methods: {
    tagsChanged(tags) {
      this.$emit('input', tags)
    },
    dlog(...things) {
      this.devLog(`tag-suggestion-${this.service.name}`, ...things)
    },
    suggest(term) {
      if (this.service && this.modelToTagMapping) {
        if (term.length === 0) {
          this.suggestedTags = []
          return
        }

        if (!(this.suggestionMethod in this.service)) {
          this.dlog(`Error, suggestion method ${this.suggestionMethod} does not exist on ${this.service.name}`)
          return
        }

        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.service[this.suggestionMethod](term).then(response => {
            /*
            Map the models returned by the response, the 
            mapping function should return an Object satisfying
            {text:string data:Object}
            */
            this.suggestedTags = response.map(this.modelToTagMapping)
          })
        }, 500)
      }
    },
    reset() {
      this.tag = ''
    }
  }

}
</script>

<style lang="scss">
  .vue-tags-input {
    max-width: 100% !important;    
  }
  .zfix {
    z-index: 900;
  }
  .tag-suggestion {
    height: min-content;
    .ti-tag, .ti-selected-item {
      background-color: $primary;
    }
  }
  .ti-input-border-primary {
    .ti-input {
      border-color: $primary;
    }
  }
</style>
