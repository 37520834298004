var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tag-suggestion" },
    [
      _c("div", { staticClass: "label-div" }, [
        _c(
          "label",
          { class: "text-sm p-1 " + (_vm.focused ? "text-primary" : "") },
          [_vm._v(_vm._s(this.label))]
        ),
      ]),
      _c("vue-tags-input", {
        class:
          "overflow-visible w-full zfix " +
          (_vm.focused ? "ti-input-border-primary" : ""),
        attrs: {
          id: "idtags",
          tags: _vm.tags,
          "autocomplete-items": _vm.suggestedTags,
          placeholder: _vm.placeholderText,
          separators: [";", " ", "\n", ","],
          "add-only-from-autocomplete": true,
        },
        on: {
          "tags-changed": _vm.tagsChanged,
          focus: function ($event) {
            _vm.focused = true
          },
          blur: function ($event) {
            _vm.focused = false
          },
        },
        scopedSlots: _vm._u([
          {
            key: "tag-center",
            fn: function (slot) {
              return [_c("div", [_vm._v(_vm._s(slot.tag.text))])]
            },
          },
        ]),
        model: {
          value: _vm.tag,
          callback: function ($$v) {
            _vm.tag = $$v
          },
          expression: "tag",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }